// src/components/Landig.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import video from '../assets/Video.mp4';
import { Modal, Button, Form } from 'react-bootstrap';
import Header from './Header'; // Make sure the path is correct
import '../styles/LandinPage.css'; // Ensure the correct path to your CSS file
import { useAuth } from './contexts/AuthContext'; // Ensure the correct path

const Landig = () => {
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, isAuthenticated } = useAuth(); // Extract login and isAuthenticated from context
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        // Navigate to the next page if authenticated
        if (isAuthenticated) {
            navigate('/next');
        }
    }, [isAuthenticated, navigate]);

    const handleExploreClick = () => {
        setShowModal(true); // Show the modal when the button is clicked
    };

    const handleCloseModal = () => {
        setShowModal(false); // Close the modal
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform login
        login(email, password);

        // Handle failed login (e.g., show an error message)
        if (isAuthenticated) {
            alert('loged in');
        }

        handleCloseModal(); // Close the modal on form submission
    };

    return (
        <div className='landing'>
            <Header />
            <video src={video} autoPlay muted loop></video>
            <div className='overlay'></div>
            <div className='content'>
                <h1>WELCOME TO GaashGp</h1>
                <p>Elevate your brand with our powerful email marketing. Tailored campaigns that drive results.</p>
                <p>Engage your audience with impact. Results-driven solutions for effective communication.</p>
                <Button variant="primary" onClick={handleExploreClick}>
                    Explore
                </Button>
            </div>

            {/* Modal for login form */}
            <Modal show={showModal} onHide={handleCloseModal} centered className='loginModal'>
                <Modal.Header closeButton>
                    <Modal.Title>Welcome to Email Marketing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Login
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Landig;








