import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import logo from '../assets/logo.PNG';

const Header = () => {
  return (
    <div className='header'>
      <div className='left-section'>
        <img src={logo} alt="Logo" />
        <div>Home</div>
        <div>Services</div>
        <div>Contact Us</div>
      </div>

      <div className='middle-section'></div>

      <div className='right-section'>
        <div>BUSINESS</div>
        <div>Pricing</div>
        <Link to="/signup">SIGN-IN</Link> {/* Use Link to navigate to Signup page */}
      </div>
    </div>
  );
}

export default Header;
