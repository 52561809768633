import '../styles/next.css';
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBook, faDollarSign, faEnvelope, faCogs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, Card, Row, Col } from 'react-bootstrap';
import SetUpEmail from './setUpEmail';
import { useAuth } from '../ccomponents/contexts/AuthContext'; // Import the useAuth hook
import CustomTemplateForm from '../ccomponents/customtemplate';

const Sidebar = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [paid, setPaid] = useState(false);
  const [showFreeModal, setShowFreeModal] = useState(false);
  const [showPaidModal, setShowPaidModal] = useState(false);
  const [companyDetails, setCompanyDetails] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { logout } = useAuth(); // Use the logout function from context

  const handleSubmit = (e) => {
    e.preventDefault();

    if (companyDetails.trim() === "") {
      alert("Please enter the specifications.");
      return;
    }

    setIsLoading(true);

    // Simulate the scraping/loading time
    setTimeout(() => {
      setIsLoading(false);
      setIsSubmitted(true);
      alert("Web scraping completed!");
    }, 10); // Simulate a 3-second web scraping
  };

  useEffect(() => {
    console.log("Paid state updated:", paid);
  }, [paid]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleTabChangeToAi = (tab) => {
    setActiveTab(tab);
  };

  // Handle modal visibility
  const handleCloseFreeModal = () => setShowFreeModal(false);
  const handleShowFreeModal = () => setShowFreeModal(true);

  const handlePaidModal = async () => {
    console.log("before paid ", paid);
    setPaid(true);
    setShowPaidModal(false);
  };

  const handleClosePaidModal = () => setShowPaidModal(false);
  const handleShowPaidModal = () => setShowPaidModal(true);

  const renderContent = () => {
    switch (activeTab) {
      case 'profile':
        return (
          <div className="profile-section">
            <h1>Profile Information</h1>
            <p><strong>Name:</strong> Javid</p>
            <p><strong>Email:</strong> lonejavid07391@gmail.com</p>
          </div>
        );
        case 'custom-template':
        return (
          <div className="custom-template">
            <CustomTemplateForm />
          </div>
        );
      case 'documentation':
        return (
          <div className="documentation-section">
            <h1>Documentation</h1>
            <h2>Overview</h2>
            <p>
              Our company provides an email marketing service that allows businesses to effectively use email for marketing and lead generation. What makes our system unique is its capability to conduct research across the web, scraping the latest information to enhance email content. Our AI generates emails that are tailored to targeted users, making them feel personalized and relevant.
            </p>
            <h2>How the System Works</h2>
            <ol>
              <li><strong>Upload Recipient List:</strong> Users can upload an Excel file containing email addresses of their desired recipients.</li>
              <li><strong>Email Generation Options:</strong> Users can choose to generate emails using our AI or upload their own custom templates.</li>
              <li><strong>Dynamic Content Creation:</strong> Our AI leverages researched data to craft personalized emails that impress recipients.</li>
            </ol>
            <h2>Pricing Plans</h2>
            <h3>Free Plan</h3>
            <p>
              - **Cost:** Free<br/>
              - **Limit:** Send up to 10 emails per day for 10 days.<br/>
              - **Features:** No AI assistance or research.
            </p>
            <h3>Paid Plan</h3>
            <p>
              - **Cost:** $300/month<br/>
              - **Features:** Unlimited emails, AI assistance, and research. Emails sent from your email address.
            </p>
            <h2>Setup for Paid Users</h2>
            <p>
              To send emails from your own email address, configure your settings to allow our server to send on your behalf.
            </p>
          </div>
        );
      case 'pricing':
        return (
          <div className="pricing-section">
            <h1>Pricing Plans</h1>
            <Row className="justify-content-center my-4">
              {/* Free Plan Card */}
              <Col md={4} className="mb-4 d-flex justify-content-center">
                <Card className="plan-card text-center" style={{ backgroundColor: 'black', color: 'white' }}>
                  <Card.Body>
                    <Card.Title className="plan-card-title">Free Plan</Card.Title>
                    <Card.Text>
                      Basic features at no cost, ideal for small campaigns and trials.
                    </Card.Text>
                    <Button variant="info" onClick={handleShowFreeModal}>
                      Free Plan
                    </Button>
                  </Card.Body>
                </Card>
              </Col>

              {/* Paid Plan Card */}
              <Col md={4} className="mb-4 d-flex justify-content-center">
                <Card className="plan-card text-center" style={{ backgroundColor: 'black', color: 'white' }}>
                  <Card.Body>
                    <Card.Title className="plan-card-title">Paid Plan</Card.Title>
                    <Card.Text>
                      Premium features for $300/month, designed for businesses with larger needs.
                    </Card.Text>
                    <Button variant="success" onClick={handleShowPaidModal}>
                      Paid Plan
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        );
      case 'email-ai':
        return (
          <div className="content-area">
            {/* Text area and submit button */}
            {isSubmitted ? (
              <SetUpEmail companyDetails={companyDetails}/> // Render SetupEmail component on successful submission
            ) : (
              <form onSubmit={handleSubmit} className="company-details-form">
                <h3>Enter company details ...</h3>
                <textarea
                  value={companyDetails}
                  onChange={(e) => setCompanyDetails(e.target.value)}
                  className="company-textarea"
                ></textarea>
                <button type="submit" className="submit-btn">
                  {isLoading ? "Loading..." : "Submit"}
                </button>
              </form>
            )}

            {/* Loading animation */}
            {isLoading && (
              <div className="loading-container">
                <div className="loading-circle"></div>
                <p className="loading-text">Scraping web, please wait...</p>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  // Handle logout
  const handleLogout = () => {
    logout(); // Call the logout function from the context
    window.location.href = '/'; // Redirect to the homepage or login page after logout
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-4 bg-dark vh-100 d-flex flex-column justify-content-between p-3 text-white position-fixed" style={{ top: 0 }}>
          <div>
            <h2 className="text-center mb-4">Menu</h2>
            <ul className="nav flex-column">
              <li className="nav-item">
                <a href="#profile" onClick={() => handleTabChange('profile')} className="nav-link text-white">
                  <FontAwesomeIcon icon={faUser} className="me-2" /> Profile
                </a>
              </li>
              <li className="nav-item">
                <a href="#documentation" onClick={() => handleTabChange('documentation')} className="nav-link text-white">
                  <FontAwesomeIcon icon={faBook} className="me-2" /> Documentation
                </a>
              </li>
              <li className="nav-item">
                <a href="#pricing" onClick={() => handleTabChange('pricing')} className="nav-link text-white">
                  <FontAwesomeIcon icon={faDollarSign} className="me-2" /> Pricing
                </a>
              </li>
              <li className="nav-item">
                <a href="#email-ai" onClick={() => handleTabChangeToAi('email-ai')} className="nav-link text-white">
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" /> Email Template With AI
                </a>
              </li>
              <li className="nav-item">
                <a href="#custom-template" onClick={() => handleTabChange('custom-template')} className="nav-link text-white">
                  <FontAwesomeIcon icon={faCogs} className="me-2" /> Custom Template
                </a>
              </li>
            </ul>
          </div>

          <div className="mt-auto">
            <button onClick={handleLogout} className="btn btn-danger w-100">
              <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Logout
            </button>
          </div>
        </div>

        <div className="col-8 offset-4 p-4 bg-secondary text-white" style={{ overflowY: 'auto', height: '100vh' }}>
          {renderContent()}
        </div>
      </div>

      {/* Free Plan Modal */}
      <Modal show={showFreeModal} onHide={handleCloseFreeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Free Plan Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="paidModal" style={{ backgroundColor: 'black', color: 'white' }}>
          <h4>Free Plan</h4>
          <p>
            The Free Plan is perfect for users who want to try out our service with basic functionality:
          </p>
          <ul>
            <li><strong>Cost:</strong> Free</li>
            <li><strong>Limit:</strong> You can send up to 10 emails per day for 10 days.</li>
            <li><strong>Email Templates:</strong> You need to create and upload your own email templates, as no AI-generated templates are provided in the Free Plan.</li>
            <li><strong>Research and Personalization:</strong> No web scraping or AI research is included. All emails will rely on the content you provide.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFreeModal}>
            Close
          </Button>
          <Button variant="success" onClick={handleClosePaidModal}>
            Go
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Paid Plan Modal */}
      <Modal show={showPaidModal} onHide={handleClosePaidModal}>
        <Modal.Header closeButton>
          <Modal.Title>Paid Plan Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="paidModal" style={{ backgroundColor: 'black', color: 'white' }}>
          <h4>Paid Plan</h4>
          <p>
            The Paid Plan offers a comprehensive email marketing solution, ideal for users who need advanced features:
          </p>
          <ul>
            <li><strong>Cost:</strong> $300/month</li>
            <li><strong>Emails:</strong> You can send unlimited emails each day.</li>
            <li><strong>Email Templates:</strong> Our AI generates dynamic and personalized email templates tailored to your audience.</li>
            <li><strong>Research and Personalization:</strong> We use web scraping and data research to ensure that each email is highly personalized and contains up-to-date, relevant information about the recipient’s industry or business.</li>
            <li><strong>Email Sending:</strong> Emails are sent directly from your own email address after setup.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePaidModal}>
            Close
          </Button>
          <Button variant="success" onClick={handlePaidModal}>
            Buy Now
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
