import React, { createContext, useState, useContext, useEffect } from 'react';

// AuthContext to manage authentication state globally
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    // Check if token exists when the component mounts
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true); // Token found, user is authenticated
        }
        setLoading(false); // Loading is done
    }, []);

    // Login function to send credentials and get a token
    const login = async (email, password) => {
        try {
            const response = await fetch('https://emailmarketing-7bf5d90cb8a1.herokuapp.com/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Login failed');
            }

            const data = await response.json();
            const { token } = data; // Expecting the token to be in the response

            // Store the token in localStorage
            localStorage.setItem('token', token);
            console.log("responce is ",response)
            setIsAuthenticated(true); // Mark the user as authenticated
        } catch (error) {
            console.error('Login error:', error);
            setIsAuthenticated(false);
            // Handle error message here (e.g., show error on the UI)
        }
    };

    // Logout function to clear the token and unauthenticate the user
    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    };

    if (loading) {
        return <div>Loading...</div>; // Can be replaced with a loading spinner or placeholder
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to access authentication state
export const useAuth = () => useContext(AuthContext);
